
.module {
  position: fixed;
  width: 100%;
  height: 100%;
}

.Modal_close__HMOnl {
  i {
    color: white !important;
  }
}
