.dropdown-more-options-hc .more-options-hc {
  padding: 0.5rem 0.5rem;
}
.dropdown-more-options-hc .more-options-hc i {
  color: #0069d9;
}
.dropdown-more-options-hc .more-options-hc:hover i, .dropdown-more-options-hc .more-options-hc:focus i {
  color: white;
}
.dropdown-more-options-hc .btn-soft-danger i {
  color: #c82333;
}
.dropdown-more-options-hc .btn-soft-danger:hover i {
  color: white;
}
.dropdown-more-options-hc .btn-soft-warning i {
  color: #e0a800;
}
.dropdown-more-options-hc .btn-soft-warning:hover i {
  color: white;
}

.document-row-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0.6;
}

.Modal_close__HMOnl i {
  color: white !important;
}

