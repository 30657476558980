.component-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

.component-content {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 3;
  pointer-events: none;
}

.Modal_close__HMOnl {
  i {
    color: white !important;
  }
}
