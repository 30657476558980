.dropdown-more-options-hc {
  .more-options-hc {
    padding: 0.5rem 0.5rem;

    i {
      color: #0069d9;
    }

    &:hover,
    &:focus {
      i {
        color: white;
      }
    }
  }

  .btn-soft-danger {
    i {
      color: #c82333;
    }

    &:hover {
      i {
        color: white;
      }
    }
  }

  .btn-soft-warning {
    i {
      color: #e0a800;
    }

    &:hover {
      i {
        color: white;
      }
    }
  }
}

.document-row-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0.6;
}

.Modal_close__HMOnl {
  i {
    color: white !important;
  }
}
